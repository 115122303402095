import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Product(props){
    AOS.init({
        duration: 1000,
        delay: 100
    });

    function showDetails(e){
        let id = e.target.id;
        let index = id - 1;

        let root_index;
        let products_index;
        let container_index;
        let product_details_index;
        let numChildNodes = e.view.document.body.childNodes.length;

        // Find index of the id="root" div childNode
        for(let i = 0; i < numChildNodes; i++){
            if(e.view.document.body.childNodes[i].id === "root"){
                root_index = i;
            }
        }

        // Find index of the id="products" div childNode
        numChildNodes = e.view.document.body.childNodes[root_index].childNodes.length;

        for(let j = 0; j < numChildNodes; j++){
            if(e.view.document.body.childNodes[root_index].childNodes[j].id === "products"){
                products_index = j;
            }
        }

        // Find index of the className="product-container" div childNode
        numChildNodes = e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes.length;

        for(let k = 0; k < numChildNodes; k++){
            if(e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[k].className === "product-container"){
                container_index = k;
            }
        }

        // Find index of the className="product-details" div childNode
        numChildNodes = e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[container_index].childNodes[0].childNodes.length;

        for(let l = 0; l < numChildNodes; l++){
            if(e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[container_index].childNodes[0].childNodes[l].className === "product-details"){
                product_details_index = l;
            }
        }

        // Retrieve the length of the products array
        let length = e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[container_index].childNodes.length;

        // Find index of the className="button-container" div childNode
        let button_index;

        numChildNodes = e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[container_index].childNodes[0].childNodes.length;

        for(let m = 0; m < numChildNodes; m++){
            if(e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[container_index].childNodes[0].childNodes[m].id === "product-button"){
                button_index = m;
            }
        }

        // If the portion of the product button clicked has the className "product-image" and the button is enabled, show the product detail pop-up and disable all the product buttons
        if(e.target.className === "product-image" && e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[container_index].childNodes[index].childNodes[0].childNodes[button_index].disabled === false){
            // Show the product details pop-up for the corresponding product button clicked
            e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[container_index].childNodes[index].childNodes[product_details_index].hidden = false;

            // Disable all product buttons
            for(let n = 0; n < length; n++){
                e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[container_index].childNodes[n].childNodes[0].childNodes[button_index].disabled = true;
            }

        // If the portion of the product button clicked has the className "product-button" and the button is enabled, show the product detail pop-up and disable all the product buttons
        }else if(e.target.className === "product-button" && e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[container_index].childNodes[index].childNodes[0].disabled === false){
            e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[container_index].childNodes[index].childNodes[product_details_index].hidden = false;

            // Disable all product buttons
            for(let o = 0; o < length; o++){
                e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[container_index].childNodes[o].childNodes[0].childNodes[button_index].disabled = true;
            }
        }

        /*==============================================================
          ============= Close navigation dropdown if shown =============
          ==============================================================*/

        let header_index;
        let nav_index;

        // Find index of the className="header-nav" div childNode
        numChildNodes = e.view.document.body.childNodes[root_index].childNodes.length;

        for(let p = 0; p < numChildNodes; p++){
            if(e.view.document.body.childNodes[root_index].childNodes[p].className === "header-nav"){
                header_index = p;
            }
        }

        // Find index of the className="nav-toggle" div childNode
        numChildNodes = e.view.document.body.childNodes[root_index].childNodes[header_index].childNodes.length;

        for(let q = 0; q < numChildNodes; q++){
            if(e.view.document.body.childNodes[root_index].childNodes[header_index].childNodes[q].className === "nav-toggle"){
                nav_index = q;
            }
        }

        // If a navigation link is clicked, close the navigation dropdown if it is shown
        if(e.view.document.body.childNodes[root_index].childNodes[header_index].childNodes[nav_index].checked){
            e.view.document.body.childNodes[root_index].childNodes[header_index].childNodes[nav_index].checked = false;
        }
    }

    function closeDetails(e){
        let id = e.target.id;
        let index = id - 1;

        let root_index;
        let products_index;
        let container_index;
        let product_details_index;
        let numChildNodes = e.view.document.body.childNodes.length;

        // Find index of the id="root" div childNode
        for(let i = 0; i < numChildNodes; i++){
            if(e.view.document.body.childNodes[i].id === "root"){
                root_index = i;
            }
        }

        // Find index of the id="products" div childNode
        numChildNodes = e.view.document.body.childNodes[root_index].childNodes.length;

        for(let j = 0; j < numChildNodes; j++){
            if(e.view.document.body.childNodes[root_index].childNodes[j].id === "products"){
                products_index = j;
            }
        }

        // Find index of the className="product-container" div childNode
        numChildNodes = e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes.length;

        for(let k = 0; k < numChildNodes; k++){
            if(e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[k].className === "product-container"){
                container_index = k;
            }
        }

        // Find index of the className="product-details" div childNode
        numChildNodes = e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[container_index].childNodes[0].childNodes.length;

        for(let l = 0; l < numChildNodes; l++){
            if(e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[container_index].childNodes[0].childNodes[l].className === "product-details"){
                product_details_index = l;
            }
        }

        // Retrieve the length of the products array
        let length = e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[container_index].childNodes.length;

        // Set scroll position of the product details pop-up back to the top of the div
        e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[container_index].childNodes[index].childNodes[product_details_index].scrollTop = 0;

        // Close out of the current product details pop-up
        e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[container_index].childNodes[index].childNodes[product_details_index].hidden = true;

        /*==============================================================
          ============== Enable all product buttons again ==============
          ==============================================================*/

        // Find index of the className="button-container" div childNode
        let button_index;

        numChildNodes = e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[container_index].childNodes[0].childNodes.length;

        for(let m = 0; m < numChildNodes; m++){
            if(e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[container_index].childNodes[0].childNodes[m].id === "product-button"){
                button_index = m;
            }
        }

        // Enable all product buttons
        for(let n = 0; n < length; n++){
            e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[container_index].childNodes[n].childNodes[0].childNodes[button_index].disabled = false;
        }
    }

    return(
        <div className="product" id={props.id}>
            <div className="button-container" id="product-button" data-aos="fade-up">
                <button className="product-button" id={props.id} onClick={showDetails}>
                    <img className="product-image" id={props.id} src={props.image} alt={props.alt} width="350" height="247" /><br /><br />{props.caption}
                </button>
            </div>
            <div className="product-details" id={props.id} hidden>
                <button className="x-button" id={props.id} onClick={closeDetails}>
                        <div className="x-button-bar1" id={props.id}></div>
                        <div className="x-button-bar2" id={props.id}></div>
                </button>
                <div className="product-details-grid">
                    <img className="product-details-image" id={props.id} src={props.image} alt={props.alt} width="350" height="247" />
                    {props.info}
                    {props.info2}
                </div>
            </div>
        </div>
    );
}

export default Product;
import React from 'react';
import { Element } from 'react-scroll';
import AOS from 'aos';
import 'aos/dist/aos.css';

function About(){
    AOS.init({
        duration: 1000,
        delay: 100
    });

    function showDetails(e){
        let id = e.target.id;
        let target = e.target.parentNode.parentNode.nextSibling;

        // If the "about-details" element's id matches the button's id, show the detail pop-up for that corresponding section
        if(target.id === id){
            target.hidden = false;
        }

        /*==============================================================
          ============= Close navigation dropdown if shown =============
          ==============================================================*/

        let root_index;
        let header_index;
        let nav_index;
        let numChildNodes = e.view.document.body.childNodes.length;

        // Find index of the id="root" div childNode
        for(let i = 0; i < numChildNodes; i++){
            if(e.view.document.body.childNodes[i].id === "root"){
                root_index = i;
            }
        }

        // Find index of the className="header-nav" div childNode
        numChildNodes = e.view.document.body.childNodes[root_index].childNodes.length;

        for(let j = 0; j < numChildNodes; j++){
            if(e.view.document.body.childNodes[root_index].childNodes[j].className === "header-nav"){
                header_index = j;
            }
        }

        // Find index of the className="nav-toggle" div childNode
        numChildNodes = e.view.document.body.childNodes[root_index].childNodes[header_index].childNodes.length;

        for(let k = 0; k < numChildNodes; k++){
            if(e.view.document.body.childNodes[root_index].childNodes[header_index].childNodes[k].className === "nav-toggle"){
                nav_index = k;
            }
        }

        // If a navigation link is clicked, close the navigation dropdown if it is shown
        if(e.view.document.body.childNodes[root_index].childNodes[header_index].childNodes[nav_index].checked){
            e.view.document.body.childNodes[root_index].childNodes[header_index].childNodes[nav_index].checked = false;
        }
    }

    function closeDetails(e){
        let id = e.target.id;

        let root_index;
        let about_index;
        let flex_index;
        let numChildNodes = e.view.document.body.childNodes.length;

        // Find index of the id="root" div childNode
        for(let l = 0; l < numChildNodes; l++){
            if(e.view.document.body.childNodes[l].id === "root"){
                root_index = l;
            }
        }

        // Find index of the id="about" div childNode
        numChildNodes = e.view.document.body.childNodes[root_index].childNodes.length;

        for(let m = 0; m < numChildNodes; m++){
            if(e.view.document.body.childNodes[root_index].childNodes[m].id === "about"){
                about_index = m;
            }
        }

        // Find index of the className="flex-row" div childNode
        numChildNodes = e.view.document.body.childNodes[root_index].childNodes[about_index].childNodes.length;

        for(let n = 0; n < numChildNodes; n++){
            if(e.view.document.body.childNodes[root_index].childNodes[about_index].childNodes[n].className === "flex-row"){
                flex_index = n;
            }
        }

        // Retrieve the length for the about-details section childNodes list
        let length = e.view.document.body.childNodes[root_index].childNodes[about_index].childNodes[flex_index].childNodes.length;

        // Close out of the current "about-details" pop-up and set the scroll bar to the top of the div
        for(let o = 0; o < length; o++){
            if(e.view.document.body.childNodes[root_index].childNodes[about_index].childNodes[2].childNodes[o].id === id){
                e.view.document.body.childNodes[root_index].childNodes[about_index].childNodes[flex_index].childNodes[o].scrollTop = 0;
                e.view.document.body.childNodes[root_index].childNodes[about_index].childNodes[flex_index].childNodes[o].hidden = true;
            }
        }
    }

    return(
        <Element className="about-section" id="about">
            <h1 className="heading-grey" data-aos="fade-up">About Cuff Cleaner</h1>
            <p className="paragraph-grey" data-aos="fade-up">Cuff Cleaner's unique system design was first introduced when police officers began voicing concerns regarding handcuff liabilities. On going scrutiny of Law Enforcement agencies and civil litigation has brought about the need for a more effective method of cleaning handcuffs and restraints.</p>
            <div className="flex-row">
                <div className="circle" data-aos="fade-up">
                    <div className="check-mark"></div>
                </div>
                <div className="flex-column" data-aos="fade-up">
                    <h2 className="subheading-grey">Who</h2>
                    <p className="column-paragraph-grey light-text">
                    Who is Cuff Cleaner for? Law enforcement, security agencies, jails, and correctional  facilities all benefit from keeping handcuffs sanitized. Recruitment Centres, Police Academy’s, the Military, and Training Facilities have also been known to use their own dirty handcuffs during training exercises. These activities only increase the chance of exposure and transfer of blood borne diseases.<br />
                    <button className="read-more-button" id="who" onClick={showDetails}>Read More ...</button></p>
                </div>
                <div className="about-details" id="who" hidden>
                    <button className="x-button-about" id="who" onClick={closeDetails}>
                            <div className="x-button-bar1" id="who"></div>
                            <div className="x-button-bar2" id="who"></div>
                    </button>
                    <h2 className="subheading-grey">Law Enforcement and Security Agencies</h2>
                    <p>A national problem exists with the lack of procedures related to the cleaning of restraining devices. Many law enforcement agencies and departments across North America do not have cleaning and disinfecting protocol/policies for handcuffs and restraints. In fact many agencies are not even complying with government legislation.<br /><br />
                     Handcuffs are used to restrain individuals associated with criminal activities, many of whom have been known to carry infectious diseases. Blood, vomit and other body fluids from these individuals can permeate the porous materials and mechanisms of the restraints. The unique design and usage of handcuffs provide organisms with an ideal breeding ground, making it virtually impossible to effectively clean restraints manually. Regular cleaning and disinfecting of restraining devices must take place, particularly after being in contact with arrested persons or immediately after obvious contamination has occurred. Police officers must have access to the Cuff Clinic 24 hours a day 7 days a week, due to changing shifts and continuous work hours.<br /><br />
                    By adopting Cuff Cleaner, your agency will be at the forefront in promoting Officer Safety, Public Protection, along with satisfying Occupational Healthy and Safety (OH&S) requirements & (OSHA) Occupational Safety & Health Administration.</p>
                    <h2 className="subheading-grey">Jails and Correctional Facilities</h2>
                    <p>Jails and Correctional facilities move/transport active criminals daily. Some of these same individuals have been known to carry a variety of dangerous diseases such as methicillin resistant staphylococcus aureus (MRSA), herpes, influenza, hepatitis, HIV-1 (AIDS virus) and other contagious skin conditions.<br /><br />
                    The unique design and usage of restraints provides organisms with an ideal breeding ground, making manual cleaning virtually ineffective. Blood, vomit and other body fluids from inmates can permeate the porous materials and mechanisms of the restraints. Facility employees handle handcuffs and restraints frequently making both guards & inmates equally at risk. Injuries, open wounds and the exposure to body fluids are all potential avenues for the transfer of disease. Regular cleaning and disinfecting of restraining devices must take place particularly after being in contact with inmates or immediately after obvious contamination. Guards must have access to the Cuff Clinic 24 hours a day 7 days a week, due to the changing shifts and continuous work hours.<br /><br />
                    By adopting Cuff Cleaner, your facility will be at the forefront in promoting Employee Safety, Public and Inmate Protection, along with satisfying Occupational Healthy and Safety (OH&S) requirements & (OSHA) Occupational Safety & Health Administration.</p>
                </div>
                <div className="circle" data-aos="fade-up">
                    <div className="check-mark"></div>
                </div>
                <div className="flex-column" data-aos="fade-up">
                <h2 className="subheading-grey">Why</h2>
                    <p className="column-paragraph-grey light-text">
                    Why use Cuff Cleaner?  Handcuffs are commonly used to restrain individuals associated with criminal activities and it's not uncommon for situations of arrest to involve a struggle, which could lead to open wounds contaminating the restraints used. Clinical testing has shown that handcuffs are an environment that sustains life. Infectious diseases can be transferred through contaminated handcuffs.<br />
                    <button className="read-more-button" id="why" onClick={showDetails}>Read More ...</button></p>
                </div>
                <div className="about-details" id="why" hidden>
                    <button className="x-button-about" id="why" onClick={closeDetails}>
                        <div className="x-button-bar1" id="why"></div>
                        <div className="x-button-bar2" id="why"></div>
                    </button>
                    <h2 className="subheading-grey">Why Clean with Cuff Cleaner?</h2>
                    <p>Leg restraints and handcuffs are commonly used to restrain individuals associated with criminal activities. Many of these individuals have been known to carry herpes, MRSA, influenza, hepatitis, HIV-1 (AIDS virus), and other organisms. It is not unusual to have apprehended individuals struggle, causing restraints to be applied under violent circumstances. This behavior leads to over-tightening of handcuffs and subsequently creates trauma around the wrists causing bleeding which in turn contaminates the handcuffs. Other areas of concern include those individuals that are already injured and bloodied or individuals with self-inflicted injuries. (This is the most common scenario)</p>
                    <div className="img-container">
                        <img src="/why_clean.jpg" alt="Cuff Clinic Cleaning Handcuffs" width="750" height="200" />
                    </div>
                    <p>Leg restraints and handcuffs are made of various metals. The unique design and usage of restraints provides organisms with an ideal breeding ground, as many of the mechanisms have blind areas. Blood, vomit, and other body fluids permeate the restraints and make it virtually impossible to effectively clean handcuffs manually. Clinical testing has shown that handcuffs are in fact an environment that does sustain life. Infectious diseases such as herpes, influenza, hepatitis, HIV-1 (AIDS virus), and other organisms can be transferred from one individual to the next through contaminated handcuffs. Cuff Cleaner Inc's system of cleaning and disinfecting is proven to be more effective than any other conventional or manual methods due to our full emersion method.</p>
                    <p>With due-diligence becoming a way of life in the field of law enforcement, improved cleaning/disinfecting methods are a must. Through modern technology, Cuff Cleaner Inc. has combined various products to create a system to produce superior cleaning performance. This is a fast, simple, cost effective and proven disinfection process designed to protect law enforcement agencies, the apprehended, and the public.</p>
                </div>
                <div className="circle" data-aos="fade-up">
                    <div className="check-mark"></div>
                </div>
                <div className="flex-column" data-aos="fade-up">
                    <h2 className="subheading-grey">How</h2>
                    <p className="column-paragraph-grey">
                    How does Cuff Cleaner work? With due-diligence becoming a way of life in the field of law enforcement, improved cleaning/disinfecting methods are a must. Through modern technology, Cuff Cleaner Inc. has combined various products to create a system to produce superior cleaning performance.<br />
                    <button className="read-more-button" id="how" onClick={showDetails}>Read More ...</button></p>
                </div>
                <div className="about-details" id="how" hidden>
                    <button className="x-button-about" id="how" onClick={closeDetails}>
                        <div className="x-button-bar1" id="how"></div>
                        <div className="x-button-bar2" id="how"></div>
                    </button>
                    <h2 className="subheading-grey">How Cuff Cleaner Cleans</h2>
                    <p>Cuff Cleaner Inc. has adopted similar technology to what is currently being used by individuals accountable for infection control within institutional settings. Decontamination of medical instruments, medical surfaces and restraints must be accomplished with similar confidence and proven results.<br /><br />
                    Cuff Cleaner Inc. combines the science of engineering and chemistry to create an incredible clean. Our cleaning unit creates ultra-high frequency sound waves, which are passed through the cuff cleaning solution. These sound waves produce high and low pressure waves within the cleaning solution, which rapidly form and collapse millions of tiny bubbles (or cavities). In the low-pressure phase, these microscopic size bubbles begin to grow and are compressed in the high-pressure phase causing the bubbles to implode. This imploding stage acts as a high-energy vacuum safely pulling debris, foreign matter and contaminates from the restraints. Cuff Cleaner Inc. combines this highly technical vacuum process with a powerful formulated disinfectant cleaner to create a perfect balance of safety, efficiency and effectiveness.<br /><br />
                    Our cleaner is a ten-minute general virucide that leaves no active residue and eliminates the development of micro organisms and micro organism strains. This product biodegrades to water and can safely be discharged into almost any standard drainage system.<br /><br />
                    To use Cuff Clinic, simply drop the cuffs into the basket provided and immerse them in the Cleaning & Disinfecting Solution for the minimum 10 minutes. Remove the restraints, rinse, wipe dry, remove excess moisture with compressed air and lubricate hinges and internal locking mechanisms using Lucas Extreme Duty Gun Oil.<br /><br />
                    <strong>Please note that:</strong></p>
                    <ul>
                        <li>Restraint discoloration and rusting could occur if restraints are left in the solution for an extended period of time</li>
                        <li>After the water rinse it is imperative to completely dry restraint or rusting could occur</li>
                        <li>Visible removal of soils should start almost immediately after the cleaning action begins</li>
                        <li>During Cuff Cleaner Inc.’s effective cleaning and decontamination process, much of the lubricants may be removed and must be replaced</li>
                        <li>Following approved practices and procedures will help ensure your restraints and cleaning units continue to function properly</li>
                        <li>Other cleaning products will damage restraints and Cuff Clinics</li>
                    </ul>
                    <p><em>CUFF CLEANER IS NOT RESPONSIBLE FOR ANY DAMAGE TO RESTRAINTS DURING CLEANING PROCESS.</em></p>
                </div>
            </div>
            <p className="paragraph-grey" data-aos="fade-up">Cuff Cleaner is a fast, simple, cost effective, and proven disinfection process designed to protect law enforcement agencies, the apprehended, and the public. By adopting Cuff Cleaner, your facility will be at the forefront in promoting Employee Safety, Public and Inmate Protection, along with satisfying Occupational Healthy and Safety (OH&S) requirements & (OSHA) Occupational Safety & Health Administration.</p>
        </Element>
    );
}

export default About;
import React from 'react';
import { Element } from 'react-scroll';
import Slide from './Slide';

function Slider(){
    let timer;
    const delayInMilliseconds = 8000;

    // Array of props to pass to Slide component
    const slides = [
        // Slide 1
        {
            id: 1,
            style: {display: "block"},
            image: "/slider_banner_1.jpg",
            alt: "Slider banner 1",
            caption: "Effective for Law Enforcement & Security Agencies",
            link: "about",
            aboutId: "who"
        },
        // Slide 2
        {
            id: 2,
            style: {display: "none"},
            image: "/slider_banner_2.jpg",
            alt: "Slider banner 2",
            caption: "Dirty Handcuffs Worth the Risk?",
            link: "about",
            aboutId: "why"
        },
        // Slide 3
        {
            id: 3,
            style: {display: "none"},
            image: "/slider_banner_3.jpg",
            alt: "Slider banner 3",
            caption: "Essential for Jails & Correctional Facilities",
            link: "about",
            aboutId: "who"
        },
    ];

    const changeSlide = (e) => {
        let currentSlideID;
        let targetSlideID;

        clearInterval(timer);

        // Get current slide id
        for(let i = 0; i < slides.length; i++){
            if(e.parentNode.childNodes[i].className === "slide"){
                if(e.parentNode.childNodes[i].style.display === "block"){
                    currentSlideID = Number(e.parentNode.childNodes[i].id);
                }
            }
        }

        // If next button is clicked, set id for next slide
        if(e.className === "next"){
            targetSlideID = currentSlideID + 1;

            // If next is clicked while on the last slide reset to slide 1
            if(targetSlideID > slides.length){
                targetSlideID = 1;
            }

        // If prev button is clicked, set id for previous slide
        }else if(e.className === "prev"){
            targetSlideID = currentSlideID - 1;

            //  If prev is clicked while on the first slide go to the last slide
            if(targetSlideID === 0 ){
                targetSlideID = slides.length;
            }
        }

        // Set display for target slide to "block" and display for all others to "none"
        for(let j = 0; j < slides.length; j++){
            // If the slide element id equals the target slide id, change its display to "block"
            if(Number(e.parentNode.childNodes[j].id) === targetSlideID){
                e.parentNode.childNodes[j].style.display = "block";
            // If the slide element id does not equal the target slide id, change its display to "none"
            }else if(Number(e.parentNode.childNodes[j].id) !== targetSlideID){
                e.parentNode.childNodes[j].style.display = "none";
            }
        }

        autoSlide(e);
    }

    // Automatically switch through slides on page load, and resume even after a slider button is clicked
    function autoSlide(e){
        if(e.className === "next"){
            timer = setInterval(() => {
                e.click();
            }, delayInMilliseconds);

        }else if(e.className === "prev"){
            timer = setInterval(() => {
                e.nextSibling.click();
            }, delayInMilliseconds);

        }else if(e.target.alt === "Slider banner 1"){
            let root_index;
            let slider_index;
            let button_index;
            let numChildNodes = e.target.ownerDocument.body.childNodes.length;

            // Find index of the id="root" div childNode
            for(let i = 0; i < numChildNodes; i++){
                if(e.target.ownerDocument.body.childNodes[i].id === "root"){
                    root_index = i;
                }
            }

            // Find index of the className="slider-container" div childNode
            numChildNodes = e.target.ownerDocument.body.childNodes[root_index].childNodes.length;

            for(let j = 0; j < numChildNodes; j++){
                if(e.target.ownerDocument.body.childNodes[root_index].childNodes[j].className === "slider-container"){
                    slider_index = j;
                }
            }

            // Find index of the id="slider-button" div childNode
            numChildNodes = e.target.ownerDocument.body.childNodes[root_index].childNodes[slider_index].childNodes.length;

            for(let k = 0; k < numChildNodes; k++){
                if(e.target.ownerDocument.body.childNodes[root_index].childNodes[slider_index].childNodes[k].id === "slider-button"){
                    button_index = k;
                }
            }

            timer = setInterval(() => {
                e.target.ownerDocument.body.childNodes[root_index].childNodes[slider_index].childNodes[button_index].click();
            }, delayInMilliseconds);
        }
    }

    // Handle "next" or "prev" button clicks for navigating slides
    function handleClick(e){
        changeSlide(e.target);
    }

    return(
        <Element className="slider-container" name="top" onLoad={autoSlide}>
            {slides.map(slide => (
                <Slide
                    id={slide.id}
                    style={slide.style}
                    image={slide.image}
                    alt={slide.alt}
                    caption={slide.caption}
                    link={slide.link}
                    aboutId={slide.aboutId}
                    key={slide.id}
                />
            ))}

            <button className="prev" id="slider-button" onClick={handleClick}>&#10094;&nbsp;</button>
            <button className="next" id="slider-button" onClick={handleClick}>&nbsp;&#10095;</button>
        </Element>
    );
}

export default Slider;
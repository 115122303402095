import HeaderNav from './components/HeaderNav';
import Slider from './components/Slider';
import About from './components/About';
import Products from './components/Products';
import ContactUs from './components/ContactUs';
import FAQ from './components/FAQ';
import Footer from './components/Footer';

function App() {
  return (
    <>
      <HeaderNav />
      <Slider />
      <About />
      <Products />
      <ContactUs />
      <FAQ />
      <Footer />
    </>
  );
}

export default App;
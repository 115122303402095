import React from 'react';
import { Link } from 'react-scroll';

function Slide(props){
    // Change the hidden attribute of the target element to false
    function showTarget(target){
        target.hidden = false;
    }

    // When Link is clicked go to the about section and open the corresponding about details popup
    function openDetails(e){
        let root_index;
        let about_index;
        let flex_index;
        let numChildNodes = e.view.document.body.childNodes.length;

        // Find index of the id="root" div childNode
        for(let i = 0; i < numChildNodes; i++){
            if(e.view.document.body.childNodes[i].id === "root"){
                root_index = i;
            }
        }

        // Find index of the id="about" div childNode
        numChildNodes = e.view.document.body.childNodes[root_index].childNodes.length;

        for(let j = 0; j < numChildNodes; j++){
            if(e.view.document.body.childNodes[root_index].childNodes[j].id === "about"){
                about_index = j;
            }
        }

        // Find index of the className="flex-row" div childNode
        numChildNodes = e.view.document.body.childNodes[root_index].childNodes[about_index].childNodes.length;

        for(let k = 0; k < numChildNodes; k++){
            if(e.view.document.body.childNodes[root_index].childNodes[about_index].childNodes[k].className === "flex-row"){
                flex_index = k;
            }
        }

        // Retrieve the length of childNodes in the part of DOM where the about-detail sections are
        numChildNodes = e.view.document.body.childNodes[root_index].childNodes[about_index].childNodes[flex_index].childNodes.length;

        // Iterate through the childNodes and find the one with both a className of "about-details" AND an id equal to the aboutId from the Slide props
        for(let l = 0; l < numChildNodes; l++){
            if((e.view.document.body.childNodes[root_index].childNodes[about_index].childNodes[flex_index].childNodes[l].className) === "about-details" && (e.view.document.body.childNodes[root_index].childNodes[about_index].childNodes[flex_index].childNodes[l].id === props.aboutId)){
                let target = e.view.document.body.childNodes[root_index].childNodes[about_index].childNodes[flex_index].childNodes[l];
                // delay showing the popup until the page scroll has stopped
                setTimeout(function(){showTarget(target)}, 550);
            }
        }
    }

    return(
        <div className="slide" id={props.id} style={props.style}>
            <img src={props.image} alt={props.alt} width="2400" height="1350" />
            <div className="caption-container">
                <h2 id="caption">{props.caption}</h2>
                 <Link to={props.link} onClick={openDetails} spy={true} smooth={true} duration={700}>Find Out More</Link>
            </div>
        </div>
    );
}

export default Slide;
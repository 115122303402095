import React from 'react';

function Footer(){
    return(
        <div className="footer">
            <p className="paragraph-white footer-paragraph">E-2200 Keating Cross Rd, Victoria, BC V8M 2A6, Canada<br />
            &#169; Cuff Cleaner Inc.</p>
        </div>
    );
}

export default Footer;
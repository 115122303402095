import React from 'react';
import { Element } from 'react-scroll';
import Product from './Product';
import AOS from 'aos';
import 'aos/dist/aos.css';

// Array of props to pass to Slide component
const products = [
    {
        id: 1,
        image: "/product_510.jpg",
        alt: "Model 510 Disinfecting Station",
        caption: "Model 510 Disinfecting Station",
        info: <div className="product-info">
                <h1 className="product-title">Model 510 Disinfecting Station</h1>
                <p className="product-description">The Cuff Cleaner System offers an unequaled method for effectively and efficiently disinfecting handcuffs and other restraint devices. By adopting Cuff Cleaner, your agency will be at the forefront in promoting Officer Safety and Public Protection, along with satisfying Occupational Health and Safety requirements.</p>
                <p><strong>MODEL 510 CLEANING PROCEDURE FOR RESTRAINTS:</strong><br /><br />
                <a href="/pdfs/510_Instructions_English.pdf" target="_blank">Model 510 Instructions - Canada English</a><br />
                <a href="/pdfs/510_Instructions_French.pdf" target="_blank">Model 510 Instructions - Canada French</a></p>
            </div>,
        info2: ""
    },
    {
        id: 2,
        image: "/product_1620.jpg",
        alt: "Model 1620",
        caption: "Model 1620",
        info: <div className="product-info">
                <h1 className="product-title">Model 1620</h1>
                <p className="product-description">The Cuff Cleaner System offers an unequaled method for effectively and efficiently disinfecting handcuffs and other restraint devices. By adopting Cuff Cleaner, your agency will be at the forefront in promoting Officer Safety and Public Protection, along with satisfying Occupational Health and Safety requirements.</p>
                <ul>
                    <li><strong>Overall Size:</strong> 190L x 170W x 220H mm</li>
                    <li><strong>Volume:</strong> 2 Liter</li>
                    <li><strong>Ultrasonic Power:</strong> 50 W</li>
                    <li><strong>Heating Power:</strong> 100 W</li>
                    <li><strong>Time Setting:</strong> 1-99 Min</li>
                    <li><strong>Temperature:</strong> 0-80 C°</li>
                </ul>
                <p><strong>Note:</strong> Disinfects 3 Sets of Handcuffs</p>
                <p>SOLD SEPARATELY:</p>
                <ul>
                    <li>Cuff Cleaner Plus Solution (1 L Bottle)</li>
                    <li>Cuff Cleaner Lubricant (4 oz Bottle)</li>
                </ul>
                <p><strong>CUFF CLEANING PROCEDURE FOR RESTRAINTS:</strong><br /><br />
                <a href="/pdfs/Instructions_English.pdf" target="_blank">Instructions - Canada English</a><br />
                <a href="/pdfs/Instructions_French.pdf" target="_blank">Instructions - Canada French</a></p>
            </div>,
        info2: ""
    },
    {
        id: 3,
        image: "/product_1730.jpg",
        alt: "Model 1730",
        caption: "Model 1730",
        info: <div className="product-info">
                <h1 className="product-title">Model 1730</h1>
                <p className="product-description">The Cuff Cleaner System offers an unequaled method for effectively and efficiently disinfecting handcuffs and other restraint devices. By adopting Cuff Cleaner, your agency will be at the forefront in promoting Officer Safety and Public Protection, along with satisfying Occupational Health and Safety requirements."</p>
                <ul>
                    <li><strong>Overall Size:</strong> 270L x 170W x 240H mm</li>
                    <li><strong>Volume:</strong> 3 Liter</li>
                    <li><strong>Ultrasonic Power:</strong> 100 W</li>
                    <li><strong>Heating Power:</strong> 100 W</li>
                    <li><strong>Time Setting:</strong> 1-99 Min</li>
                    <li><strong>Temperature:</strong> 0-80 C°</li>
                </ul>
                <p><strong>Note:</strong> Cleans 7 sets of handcuffs</p>
                <p>SOLD SEPARATELY:</p>
                <ul>
                    <li>Cuff Cleaner Plus Solution (1 L Bottle)</li>
                    <li>Cuff Cleaner Lubricant (4 oz Bottle)</li>
                </ul>
                <p><strong>CUFF CLEANING PROCEDURE FOR RESTRAINTS:</strong><br /><br />
                <a href="/pdfs/Instructions_English.pdf" target="_blank">Instructions - Canada English</a><br />
                <a href="/pdfs/Instructions_French.pdf" target="_blank">Instructions - Canada French</a></p>
            </div>,
        info2: ""
    },
    {
        id: 4,
        image: "/product_1910.jpg",
        alt: "Model 1910",
        caption: "Model 1910",
        info: <div className="product-info">
                <h1 className="product-title">Model 1910</h1>
                <p className="product-description">The Cuff Cleaner System offers an unequaled method for effectively and efficiently disinfecting handcuffs and other restraint devices. By adopting Cuff Cleaner, your agency will be at the forefront in promoting Officer Safety and Public Protection, along with satisfying Occupational Health and Safety requirements.</p>
                <ul>
                    <li><strong>Overall Size:</strong> 530L x 165W x 310H mm</li>
                    <li><strong>Volume:</strong> 10 Liter</li>
                    <li><strong>Ultrasonic Power:</strong> 200 W</li>
                    <li><strong>Heating Power:</strong> 400 W</li>
                    <li><strong>Time Setting:</strong> 1-99 Min</li>
                    <li><strong>Temperature:</strong> 0-80 C°</li>
                </ul>
                <p><strong>Note:</strong> Cleans approx. 50 sets of handcuffs</p>
                <p>SOLD SEPARATELY:</p>
                <ul>
                    <li>Cuff Cleaner Plus Solution (1 L Bottle)</li>
                    <li>Cuff Cleaner Lubricant (4 oz Bottle)</li>
                </ul>
                <p><strong>CUFF CLEANING PROCEDURE FOR RESTRAINTS:</strong><br /><br />
                <a href="/pdfs/Instructions_English.pdf" target="_blank">Instructions - Canada English</a><br />
                <a href="/pdfs/Instructions_French.pdf" target="_blank">Instructions - Canada French</a></p>
            </div>,
        info2: ""
    },
    {
        id: 5,
        image: "/product_plus_dis.jpg",
        alt: "Cuff Cleaner Plus Disinfectant",
        caption: "Cuff Cleaner Plus Disinfectant",
        info: <div className="product-info">
                <h1 className="product-title" style={{fontSize: "36px"}}>Cuff Cleaner Plus Disinfectant</h1>
                <p className="product-description" style={{fontSize: "16px"}}>Cuff Cleaner is proud to introduce the new CUFF CLEANER PLUS solution to accompany our Cuff Clinic models. Scientifically chosen, this concentrated solution is DIN & EPA registered and available at a low cost.<br /><br /> CUFF CLEANER PLUS is a concentrated one-step disinfectant cleaner that is effective against a broad spectrum of bacteria, is a virucide, a fungicide, and inhibits the growth of mold and mildew and their odors when used as directed.<br /><br />
                CUFF CLEANER PLUS is a hospital grade disinfectant, according to the AOAC Use-Dilution Test method, Virucidal* according to the virucidal qualification on hard, inanimate surfaces, Fungicidal according to the AOAC fungicidal test, modified in the presence of 5% organic serum against: Pseudomonas aeruginosa, Proteus vulgaris, Staphylococcus aureus, Salmonella choleraesuis, Brevibacterium</p>
            </div>,
        info2:<div className="product-info-2" style={{fontSize: "16px", maxWidth: "70vw"}}>
                <p>ammoniagenes, Chlamydia psittaci, Escherichia coli, Enterobacter aerogenes, Enterobacter cloacae, Klebsiella pneumoniae, Salmonella typhi, Salmonella typhimurium, Salmonella schottmuelleri, Serratia marcescens, Shigella flexneri, Shigella sonnei, Enterococcus (Streptococcus) faecalis, Streptococcus pyogenes, Shigella dysenteriae, Staphylococcus epidermidis, Escherichia coli - Methicillin resistant, Enterococcus faecalis - Vancomycin-resistant (VRE), Enterococcus (Streptococcus) faecalis- Methicillin-resistant, Klebsiella pneumoniae - Methicillin resistant, Staphylococcus aureus - Methicillin resistant (MRSA), Vancomycin Intermediate-Resistant Staphylococcus aureus (VISA), Aspergillus niger, Candida albicans, Trichophyton mentagrophytes, *Hepatitis B Virus, *Herpes Simplex Type 1, *Herpes Simplex Type 2, *HIV-1 (AIDS virus), *Influenza A/PR, *Rubella and *Vaccinia.<br /><br />
                Special Instructions for Cleaning and Decontamination Against Blood borne Pathogens and Surfaces/Objects Soiled with Blood/Body Fluids: This product is effective against HIV-1 (Human Immunodeficiency Virus Type 1) in hard water up to 400 ppm (CaCo 3 ), and in the presence of organic soil (5% blood serum).<br /><br />
                <strong>PREPARATION OF USE SOLUTION WITH CUFF CLINIC:</strong><br /><br />
                Cuff Cleaner Concentrate Dilution by Model - 510 - 1 Ounce / 620 - 1.5 Ounces / 730 - 3 Ounces / 860 - 5 Ounces / 990 - 11 Ounces<br /><br />
                Measure & dilute the Cuff Cleaner Plus Solution based on the below Model requirements:<br />
                Contact Time : To be effective against the above listed micro-organisms, a 10 minute contact time in Cuff Clinic is required. Treated surfaces must remain wet for 10 minutes. Prepare a fresh solution daily or when use-solution becomes visibly dirty.<br /><br />
                <strong>DISINFECTION :</strong><br /><br />
                General Use : For heavily soiled areas, a preliminary cleaning is required. For sprayer applications, use a coarse spray device. Spray 15-20 cm from surface, rub with brush, sponge or cloth. Do not breathe spray mist.<br /><br />
                <strong>PRODUCT MATERIAL SAFETY DATA SHEET INFORMATION:</strong><br /><br />
                <a href="/pdfs/CC-CuffCleanerPlus_ENG_CDN.pdf" target="_blank">Solution Cuff Cleaner Plus (MSDS) - Canada English</a><br />
                <a href="/pdfs/CC-CuffCleanerPlus_FRI_CDN.pdf" target="_blank">Solution Cuff Cleaner Plus (MSDS) - Canada French</a></p>
            </div>
    },
    {
        id: 6,
        image: "/product_handcuff_lubricant.jpg",
        alt: "Handcuff Lubricant",
        caption: "Handcuff Lubricant",
        info: <div className="product-info">
                <h1 className="product-title">Handcuff Lubricant</h1>
                <p className='product-description'>Lucas Extreme Duty Gun Oil is a special blend of oil and petroleum-extracted additives producing a lubrication suitable for use on handcuff metal. Protects metal parts from rust, wear and moisture during extreme conditions.<br /><br />
                Available in individual 2 oz bottles or case of 18.<br /><br />
                <a href="/pdfs/Microsoft Word - SDS_Extreme-Gun-Oil.doc.pdf" target="_blank">SDS Extreme Gun Oil Material Safety Data Sheet</a></p>
                <img src="/3 products.jpg" alt="Handcuff Lubricant - Lucas Oil" width="760" height="208" style={{maxWidth: "100%", height: "auto", marginTop: "50px"}}/>
            </div>,
        info2: ""
    },
];

function Products(){
    AOS.init({
        duration: 1000,
        delay: 100
    });

    return(
        <Element className="products-section" id="products">
            <h1 className="heading-white" data-aos="fade-up">Cuff Cleaner Products</h1>
            <p className="paragraph-white" data-aos="fade-up">Cuff Cleaner Inc. has developed a variety of products to promote officer safety and public protection. Our models are available to accommodate agencies of various sizes. Proactive agencies strategically place the units in areas where prisoners are processed. It is imperative that all personnel have access to the Cuff Clinic 24 hours a day 7 days a week.</p>
            <div className="product-container">
                {products.map(product => (
                    <Product
                        key={product.id}
                        id={product.id}
                        image={product.image}
                        alt={product.alt}
                        caption={product.caption}
                        info={product.info}
                        info2={product.info2}
                        length={Products.length}
                    />
                ))}
            </div>
        </Element>
    );
}

export default Products;
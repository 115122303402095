import React from 'react';
import Nav from './Nav';

function HeaderNav(){
  return(
    <header className="header-nav">
      <a href="index.html"><img className="logo" src="/cuff-cleaner-logo_200x24.png" alt="Cuff Cleaner logo" width="200" height="24"/></a>
      <input type="checkbox" id="nav-toggle" className="nav-toggle" />
      <Nav />
      <label htmlFor="nav-toggle" className="nav-toggle-label">
        <a>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </a>
      </label>
	  </header>
  );
}

export default HeaderNav;
import React from 'react';
import { Link } from 'react-scroll';

function Nav(){

    function closeDetails(e){
        /*=============================================================
          ============ Close any open about-detail" popups ============
          =============================================================*/

        let root_index;
        let about_index;
        let flex_index;
        let numChildNodes = e.view.document.body.childNodes.length;

        // Find index of the id="root" div childNode
        for(let i = 0; i < numChildNodes; i++){
            if(e.view.document.body.childNodes[i].id === "root"){
                root_index = i;
            }
        }

        // Find index of the id="about" div childNode
        numChildNodes = e.view.document.body.childNodes[root_index].childNodes.length;

        for(let j = 0; j < numChildNodes; j++){
            if(e.view.document.body.childNodes[root_index].childNodes[j].id === "about"){
                about_index = j;
            }
        }

        // Find index of the className="flex-row" div childNode
        numChildNodes = e.view.document.body.childNodes[root_index].childNodes[about_index].childNodes.length;

        for(let k = 0; k < numChildNodes; k++){
            if(e.view.document.body.childNodes[root_index].childNodes[about_index].childNodes[k].className === "flex-row"){
                flex_index = k;
            }
        }

        // Retrieve the length for the about-details section childNodes list
        let aboutLength = e.view.document.body.childNodes[root_index].childNodes[about_index].childNodes[flex_index].childNodes.length

        // Close out of any about detail popups that are shown and set scroll position back to the top of the div
        for(let l = 0; l < aboutLength; l++){
            if(e.view.document.body.childNodes[root_index].childNodes[about_index].childNodes[flex_index].childNodes[l].className === "about-details"){
                if(e.view.document.body.childNodes[root_index].childNodes[about_index].childNodes[flex_index].childNodes[l].hidden === false){
                    e.view.document.body.childNodes[root_index].childNodes[about_index].childNodes[flex_index].childNodes[l].scrollTop = 0;
                    e.view.document.body.childNodes[root_index].childNodes[about_index].childNodes[flex_index].childNodes[l].hidden = true;
                }

            }
        }

        /*==============================================================
          ============ Close any open product-detail popups ============
          ==============================================================*/

        let products_index;
        let container_index;
        let product_details_index;

        // Find index of the id="products" div childNode
        numChildNodes = e.view.document.body.childNodes[root_index].childNodes.length;

        for(let m = 0; m < numChildNodes; m++){
            if(e.view.document.body.childNodes[root_index].childNodes[m].id === "products"){
                products_index = m;
            }
        }

        // Find index of the className="product-container" div childNode
        numChildNodes = e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes.length;

        for(let n = 0; n < numChildNodes; n++){
            if(e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[n].className === "product-container"){
                container_index = n;
            }
        }

        // Find index of the className="product-details" div childNode
        numChildNodes = e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[container_index].childNodes[0].childNodes.length;

        for(let o = 0; o < numChildNodes; o++){
            if(e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[container_index].childNodes[0].childNodes[o].className === "product-details"){
                product_details_index = o;
            }
        }

        // Retrieve the length of the products array
        let productsLength = e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[container_index].childNodes.length;

        // Close out of any product detail popups that are shown and set scroll position back to the top of the div
        for(let p = 0; p < productsLength; p++){
            if(e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[container_index].childNodes[p].childNodes[product_details_index].hidden === false){
                e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[container_index].childNodes[p].childNodes[product_details_index].scrollTop = 0;
                e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[container_index].childNodes[p].childNodes[product_details_index].hidden = true;
            }
        }

        /*==============================================================
          ============== Enable all product buttons again ==============
          ==============================================================*/

        // Find index of the className="button-container" div childNode
        let button_index;

        numChildNodes = e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[container_index].childNodes[0].childNodes.length;

        for(let q = 0; q < numChildNodes; q++){
            if(e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[container_index].childNodes[0].childNodes[q].id === "product-button"){
                button_index = q;
            }
        }

        // Enable all product buttons
        for(let r = 0; r < productsLength; r++){
            e.view.document.body.childNodes[root_index].childNodes[products_index].childNodes[container_index].childNodes[r].childNodes[0].childNodes[button_index].disabled = false;
        }

        /*==============================================================
          ============= Close navigation dropdown if shown =============
          ==============================================================*/

        let header_index;
        let nav_index;

        // Find index of the className="header-nav" div childNode
        numChildNodes = e.view.document.body.childNodes[root_index].childNodes.length;

        for(let s = 0; s < numChildNodes; s++){
            if(e.view.document.body.childNodes[root_index].childNodes[s].className === "header-nav"){
                header_index = s;
            }
        }

        // Find index of the className="nav-toggle" div childNode
        numChildNodes = e.view.document.body.childNodes[root_index].childNodes[header_index].childNodes.length;

        for(let t = 0; t < numChildNodes; t++){
            if(e.view.document.body.childNodes[root_index].childNodes[header_index].childNodes[t].className === "nav-toggle"){
                nav_index = t;
            }
        }

        // If a navigation link is clicked, close the navigation dropdown if it is shown
        if(e.view.document.body.childNodes[root_index].childNodes[header_index].childNodes[nav_index].checked){
            e.view.document.body.childNodes[root_index].childNodes[header_index].childNodes[nav_index].checked = false;
        }
    }

    return(
        <nav>
            <ul>
                <li>
                    <Link to="top" spy={true} smooth={true} duration={700} onClick={closeDetails}>Home</Link>
                </li>
                <li>
                    <Link to="about" spy={true} smooth={true} duration={700} onClick={closeDetails}>About</Link>
                </li>
                <li>
                    <Link to="products" spy={true} smooth={true} duration={700} onClick={closeDetails}>Products</Link>
                </li>
                <li>
                    <Link to="contact" spy={true} smooth={true} duration={700} onClick={closeDetails}>Contact</Link>
                </li>
                <li>
                    <Link to="faq"spy={true} smooth={true} duration={700} onClick={closeDetails}>FAQ</Link>
                </li>
            </ul>
        </nav>
    );
}

export default Nav;
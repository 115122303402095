import React from 'react';
import { Element } from 'react-scroll';
import AOS from 'aos';
import 'aos/dist/aos.css';

function FAQ(){
    AOS.init({
        duration: 1000,
        delay: 100
    });

    function toggleAnswer(e){
        // Toggle faq-answer div visibility
        if(e.target.parentNode.nextSibling.hidden === true){
            e.target.parentNode.nextSibling.hidden = false;
            e.target.innerHTML = "&#8722;";
        } else {
            e.target.parentNode.nextSibling.hidden = true;
            e.target.innerHTML = "&#43;";
        }
        AOS.refresh();
    }

    return(
        <Element className="faq-section" id="faq">
            <h1 className="heading-grey" data-aos="fade-up" data-aos-anchor-placement="top-bottom">FAQ</h1>
            <div className="faq-question" data-aos="fade-up" data-aos-anchor-placement="top-bottom">Q: What is the difference between the Model 510 Disinfecting Station, and the Cuff Clinic Models?<button className="plus-button" id="1" onClick={toggleAnswer}>&#43;</button></div>
            <div className="faq-answer" id="1" data-aos="fade-up" data-aos-anchor-placement="top-bottom" hidden>A: The Model 510 does not have the ultrasonic capability that the Cuff Clinic models have, therefore does not remove debris. Disinfection occurs with the full emersion method (F.E.M) into the Cuff Cleaner Plus solution. Using the Cuff Cleaner Plus Solution, the Model 510 has the same kill claims as all the other Cuff Clinic Models.</div>
            <div className="faq-question" data-aos="fade-up" data-aos-anchor-placement="top-bottom">Q: When should I change my Cuff Cleaner Plus Solution?<button className="plus-button" id="2" onClick={toggleAnswer}>&#43;</button></div>
            <div className="faq-answer" id="2" data-aos="fade-up" data-aos-anchor-placement="top-bottom" hidden>A: We recommend setting up procedures to change the solutions once a week.</div>
            <div className="faq-question" data-aos="fade-up" data-aos-anchor-placement="top-bottom">Q: My handcuffs are starting to rust after using the Cuff Clinic, why is this happening?<button className="plus-button" id="3" onClick={toggleAnswer}>&#43;</button></div>
            <div className="faq-answer" id="3" data-aos="fade-up" data-aos-anchor-placement="top-bottom" hidden>A: This may be occurring after the recommended tap water rinse of the restraint. It is imperative that the restraints be completely free of water. Using compressed air and Cuff Cleaner Lubricant is highly recommended to keep this from occurring. The Cuff Cleaner Plus Solution has rust inhibiting qualities; however the rusting could occur after the water rinse stage. Please be sure to remove all moisture form the restraints and lubricate.</div>
            <div className="faq-question" data-aos="fade-up" data-aos-anchor-placement="top-bottom">Q: Is my agency complying with Federal Labor Code, OH&S or OSHA requirements?<button className="plus-button" id="4" onClick={toggleAnswer}>&#43;</button></div>
            <div className="faq-answer" id="4" data-aos="fade-up" data-aos-anchor-placement="top-bottom" hidden>A: Cuff Cleaner Inc. recommends researching your state or provinces legislation to learn the legislation surrounding infectious diseases and the methods and protections against the spread of deadly diseases and viruses.</div>
            <div className="faq-question" data-aos="fade-up" data-aos-anchor-placement="top-bottom">Q: Will the Cuff Cleaner units clean all types of handcuffs and restraints?<button className="plus-button" id="5" onClick={toggleAnswer}>&#43;</button></div>
            <div className="faq-answer" id="5" data-aos="fade-up" data-aos-anchor-placement="top-bottom" hidden>A: For specialty handcuffs or restraints (Plastic or Lexan) please contact Cuff Cleaner Inc. for further information.</div>
            <div className="faq-question" data-aos="fade-up" data-aos-anchor-placement="top-bottom">Q: Why is a special solution required for my Cuff Clinic model?<button className="plus-button" id="6" onClick={toggleAnswer}>&#43;</button></div>
            <div className="faq-answer" id="6" data-aos="fade-up" data-aos-anchor-placement="top-bottom" hidden>A: Water alone has minimal cleaning or disinfecting capabilities. Restraints are made of various metals with hidden mechanisms. Soil adheres to the restraints creating an excellent breeding ground. The solution removes and breaks down the bond between contaminates and surface metal. This allows for the disinfecting process to take place. The Cuff Clinic accelerates the process and cleans the hidden areas of the restraints, which are difficult to clean otherwise.</div>
            <div className="faq-question" data-aos="fade-up" data-aos-anchor-placement="top-bottom">Q: Do I need to fill solution to the line on the Cuff Clinic models, or can I save on solution using less?<button className="plus-button" id="7" onClick={toggleAnswer}>&#43;</button></div>
            <div className="faq-answer" id="7" data-aos="fade-up" data-aos-anchor-placement="top-bottom" hidden>A: The Cuff Clinic models have a "Tuned" system with transducers. Improper solution levels will change characteristics of the tank environment. This in turn alters the system frequency, decreases effectiveness, and if repeated continuously it could potentially damage the cleaning unit. The solution level should always be at the recommended level indicated on Cuff Clinic and also outlined in your instruction manual. Maintaining proper solution levels provides optimum circulation of solution around restraints, protects the transducers, and extends the life of the Cuff Clinic.<br /><br />
            The "Fill Lines" on both the Cuff Clinic & Model 510 units are a reference line for proper dilution of Cuff Cleaner Solution and water.</div>
            <div className="faq-question" data-aos="fade-up" data-aos-anchor-placement="top-bottom">Q: Why shouldn't I leave my Cuff Clinic unit on constantly?<button className="plus-button" id="8" onClick={toggleAnswer}>&#43;</button></div>
            <div className="faq-answer" id="8" data-aos="fade-up" data-aos-anchor-placement="top-bottom" hidden>A: Low solution levels can seriously damage your cleaner. Should the unit be continuously operating, low solution levels will result. Turning the unit off while not in use and monitoring the solution levels when in use will yield many years of trouble free service from your cleaning unit.</div>
            <div className="faq-question" data-aos="fade-up" data-aos-anchor-placement="top-bottom">Q: Can I clean more than one pair of handcuffs at a time?<button className="plus-button" id="9" onClick={toggleAnswer}>&#43;</button></div>
            <div className="faq-answer" id="9" data-aos="fade-up" data-aos-anchor-placement="top-bottom" hidden>A: Time and efficiency are always a factor in our busy lives. Cuff Cleaner Inc. recognizes this, and has devised a cleaner and solution to cut down the time and assist our cleaning methods. Certainly numerous restraints can be placed in the basket at one time, as long as all the restraints are completely submerged in the solution. Larger cleaning units are available for agencies such as detaining facilities that have higher quantities and volumes of cleaning needs.</div>
            <div className="faq-question" data-aos="fade-up" data-aos-anchor-placement="top-bottom">Q: Can I have my Cuff Clinic station in the same room as an intoxilyzer?<button className="plus-button" id="10" onClick={toggleAnswer}>&#43;</button></div>
            <div className="faq-answer" id="10" data-aos="fade-up" data-aos-anchor-placement="top-bottom" hidden>A: No, the Cuff Cleaner Plus Solution has a very small parts per million (ppm) of alcohol in our fragrance therefore we do not recommend this.</div>
        </Element>
    );
}

export default FAQ;
import React, { useState } from 'react';
import { Element } from 'react-scroll';
import AOS from 'aos';
import 'aos/dist/aos.css';

function ContactUs(){
    AOS.init({
        duration: 1000,
        delay: 100
    });

    const API_PATH = "/api/index.php";
    const [status, setStatus] = useState("Send");

    function closeDetails(e){
        let root_index;
        let contact_index;
        let feedback_index;
        let success_index;
        let failure_index;
        let numChildNodes = e.view.document.body.childNodes.length;

        // Find index of the id="root" div childNode
        for(let i = 0; i < numChildNodes; i++){
            if(e.view.document.body.childNodes[i].id === "root"){
                root_index = i;
            }
        }

        // Find index of the id="contact" div childNode
        numChildNodes = e.view.document.body.childNodes[root_index].childNodes.length;

        for(let j = 0; j < numChildNodes; j++){
            if(e.view.document.body.childNodes[root_index].childNodes[j].id === "contact"){
                contact_index = j;
            }
        }

        // Find index of className="form-feedback" div childNode
        numChildNodes = e.view.document.body.childNodes[root_index].childNodes[contact_index].childNodes.length;

        for(let k = 0; k < numChildNodes; k++){
            if(e.view.document.body.childNodes[root_index].childNodes[contact_index].childNodes[k].className === "form-feedback"){
                feedback_index = k;
            }
        }

        // Find index of className="message-success" div childNode
        numChildNodes = e.view.document.body.childNodes[root_index].childNodes[contact_index].childNodes[feedback_index].childNodes.length;

        for(let l = 0; l < numChildNodes; l++){
            if(e.view.document.body.childNodes[root_index].childNodes[contact_index].childNodes[feedback_index].childNodes[l].className === "message-success"){
                success_index = l;
            }
        }

        // Find index of className="message-failure" div childNode
        numChildNodes = e.view.document.body.childNodes[root_index].childNodes[contact_index].childNodes[feedback_index].childNodes.length;

        for(let m = 0; m < numChildNodes; m++){
            if(e.view.document.body.childNodes[root_index].childNodes[contact_index].childNodes[feedback_index].childNodes[m].className === "message-failure"){
                failure_index = m;
            }
        }

        let target = e.view.document.body.childNodes[root_index].childNodes[contact_index].childNodes[feedback_index];

        target.hidden = true;
        target.childNodes[success_index].hidden = true;
        target.childNodes[failure_index].hidden = true;
    }

    function formFeedback(e, status){
        let feedback_index;
        let numChildNodes = e.target.parentNode.childNodes.length;

        // Find index of the className="form-feedback" div childNode
        for(let n = 0; n < numChildNodes; n++){
            if(e.target.parentNode.childNodes[n].className === "form-feedback"){
                feedback_index = n;
            }
        }

        // Retrieve number of childNodes to div element form-feedback
        numChildNodes = e.target.parentNode.childNodes[feedback_index].childNodes.length;

        // Show form-feedback popup
        e.target.parentNode.childNodes[feedback_index].hidden = false;

        for(let i = 0; i < numChildNodes; i++){
            if(status === "Message Sent" && e.target.parentNode.childNodes[feedback_index].childNodes[i].className === "message-success"){
                e.target.parentNode.childNodes[feedback_index].childNodes[i].hidden = false;
            }else if(status === "ERROR" && e.target.parentNode.childNodes[feedback_index].childNodes[i].className === "message-failure"){
                e.target.parentNode.childNodes[feedback_index].childNodes[i].hidden = false;
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus("Sending...");

        const { message, company, email, name } = e.target.elements;

        let details = {
            message: message.value,
            company: company.value,
            email: email.value,
            name: name.value,
        };

        let response = await fetch(`${API_PATH}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(details),
            mode: "cors",
        });

        // Reset form input values after form submission
        e.target[0].value = ""; // e.target.id = message
        e.target[1].value = ""; // e.target.id = company
        e.target[2].value = ""; // e.target.id = email
        e.target[3].value = ""; // e.target.id = name
        setStatus("Send");

        // Handle result of form submission and show feedback based on success or failure
        let result = await response.json();
        formFeedback(e, result.status);
    };

    return(
        <Element className="contact-section" id="contact">
            <h1 className="heading-white" data-aos="fade-up">Get In Touch</h1>
            <p className="paragraph-white contact-paragraph" data-aos="fade-up">Use the form below to send us an email, or contact us by phone at 250-652-5266.</p>
            <form className="contact-form" onSubmit={handleSubmit} data-aos="fade-up">
              <textarea type="text" id="message" rows="18" cols="90" maxLength="6000" placeholder="Message" required/>
              <input type="text" id="company" placeholder="Company/Detachment" required/>
              <div>
                <input type="email" id="email" placeholder="Email Address" required/>
                <input type="text" id="name" placeholder="Full Name" required/>
                <input type="submit" className="send" value={status} required/>
              </div>
            </form>
            <div className="form-feedback" hidden>
                <div className="message-success" hidden>
                    <h2>Contact Form Submission Status</h2>
                    <p>Your message has been sent! We will reply as soon as possible.</p>
                    <button className="ok-button" onClick={closeDetails}>OK</button>
                </div>
                <div className="message-failure" hidden>
                <h2>Contact Form Submission Status</h2>
                    <p>There was an error sending your message. Please try again.</p>
                    <button className="ok-button" onClick={closeDetails}>OK</button>
                </div>
            </div>
        </Element>
    );
}

export default ContactUs;